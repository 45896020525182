<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Документы</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-row align="center">
        <!-- <document-card v-for="(document, index) in documents" :key="index" :doc="document"></document-card> -->
        <document-card
          :doc="{
            title: 'Заявление',
            routeName: 'StatementDocument',
            descr:
              'Необходимо для подписания документов онлайн с помощью буквенно-цифрового кода.',
            status: documentsStatus ? documentsStatus.statement : 0,
            slug: null,
            allowed: true,
          }"
        ></document-card>
        
        <document-card
          :doc="{
            title: 'Платежные реквизиты',
            routeName: 'PaymentDetails',
            descr:
              'Реквизиты, на которые вам будет перечисляться роялти с продаж книг.',
            status: documentsStatus ? documentsStatus.payout : 0,
            slug: null,
            allowed: true,
          }"
        ></document-card>


        <document-card
          :doc="{
            title: 'Договор подписки',
            routeName: 'ContractsDetail',
            descr:
              'Необходим для открытия продаж в формате &quot;Подписка&quot;.',
            status: documentsStatus ? documentsStatus.sub : 0,
            slug: 'sub',
            allowed: documentsStatus && documentsStatus.statement == 2 ? true : false,
          }"
        ></document-card>

        <document-card
          :doc="{
            title: 'Договор продажи',
            routeName: 'ContractsDetail',
            descr:
              'Необходим для открытия продаж законченных книг.',
            status: documentsStatus ? documentsStatus.sell : 0,
            slug: 'sell',
            allowed: documentsStatus && documentsStatus.statement == 2 ? true : false,
          }"
        ></document-card>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import DocumentCard from "./DocumentCard.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    DocumentCard,
  },
  data: () => ({

  }),
  methods: {
    checkCommerceDocumentsStatus() {
      this.$store.dispatch("documents/checkCommerceDocumentsStatus");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      documentsStatus: "documents/documentsStatus",
    }),
  },
  mounted() {
    this.checkCommerceDocumentsStatus();
  },
};
</script>
