var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"tile":"","flat":"","elevation":"1"}},[_c('v-btn',{staticClass:"no-active",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("$vuetify.icons.arrow_left")])],1),_c('v-toolbar-title',[_vm._v("Документы")]),_c('v-spacer')],1),_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('document-card',{attrs:{"doc":{
          title: 'Заявление',
          routeName: 'StatementDocument',
          descr:
            'Необходимо для подписания документов онлайн с помощью буквенно-цифрового кода.',
          status: _vm.documentsStatus ? _vm.documentsStatus.statement : 0,
          slug: null,
          allowed: true,
        }}}),_c('document-card',{attrs:{"doc":{
          title: 'Платежные реквизиты',
          routeName: 'PaymentDetails',
          descr:
            'Реквизиты, на которые вам будет перечисляться роялти с продаж книг.',
          status: _vm.documentsStatus ? _vm.documentsStatus.payout : 0,
          slug: null,
          allowed: true,
        }}}),_c('document-card',{attrs:{"doc":{
          title: 'Договор подписки',
          routeName: 'ContractsDetail',
          descr:
            'Необходим для открытия продаж в формате "Подписка".',
          status: _vm.documentsStatus ? _vm.documentsStatus.sub : 0,
          slug: 'sub',
          allowed: _vm.documentsStatus && _vm.documentsStatus.statement == 2 ? true : false,
        }}}),_c('document-card',{attrs:{"doc":{
          title: 'Договор продажи',
          routeName: 'ContractsDetail',
          descr:
            'Необходим для открытия продаж законченных книг.',
          status: _vm.documentsStatus ? _vm.documentsStatus.sell : 0,
          slug: 'sell',
          allowed: _vm.documentsStatus && _vm.documentsStatus.statement == 2 ? true : false,
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }