<template>
  <v-col class="text-left" v-if="doc" cols="12">
    <v-card class="doc-menu-card">
      <v-card-title>
        <v-icon x-large v-text="icon" :color="iconColor"></v-icon>

        <router-link
          class="ml-4"
          :to="{ name: doc.routeName, params: { slug: doc.slug } }"
          >{{ doc.title }}</router-link
        >
      </v-card-title>
      <v-card-text>
        {{ doc.descr }}
      </v-card-text>
      <v-card-actions class="mt-auto px-4 pb-4 pt-0">
        <v-btn v-if="doc.allowed" color="primary" small :to="{ name: doc.routeName, params: { slug: doc.slug } }">{{
          +doc.status ? "Посмотреть детали" : "Подключить"
        }}</v-btn>
        <div v-else>Выполните предыдущие условия</div>
      </v-card-actions>
    </v-card>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
// import BookInfo from "./BookInfo.vue";
export default {
  // components: { BookInfo },
  props: ["doc"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(["currentUser"]),
    iconColor() {
      let color = "grey lighten-1";
      if (this.doc) {
        let status = +this.doc.status;
        if (status == 1) {
          color = "primary";
        } else if (status == 2) {
          color = "success";
        }
      
      }
      return color;
    },
    icon() {
      let icon = '$vuetify.icons.close_circle';

      if (this.doc) {
        let status = +this.doc.status;
        if (status == 1) {
          icon = '$vuetify.icons.alert_circle';
        } else if (status == 2) {
          icon = '$vuetify.icons.check_circle';
        }
      }
      return icon;
    },
  },
};
</script>
